import 'jquery';
import 'bootstrap';
import './css/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

window.$ = require('jquery');

// Smooth Scrolling jump links
$('a[href*=\\#]').on('click', function (event) {
	event.preventDefault();
	$('html,body').animate({ scrollTop: $(this.hash).offset().top }, 500);
});